<template>
  <Layout>
    <div class="container-study">
      <div class="schedule-header">
        <div>
          <BreadCrumb
            :items="[
              { name: 'Home', path: '/' },
              { name: 'Cronograma de estudos', path: '/cronograma/introducao' },
              { name: 'Meu Cronograma', path: '#' },
            ]"
            v-if="!getIsMobile"
          />
          <h1>Cronograma de estudos - Semana {{ this.getWeekNumber }}</h1>
          <p>
            Estude o conteúdo das semanas e esteja preparado(a) para fazer a
            prova!
          </p>
        </div>
        <div class="container-controls">
          <div class="week-configuration">
            <ButtonPlay
              type="outline"
              @click="goToConfiguration"
              :fullWidth="getIsMobile"
            >
              <div class="icon-button">
                <IconSax type="outline" name="setting-2" />
                Configurações
              </div>
            </ButtonPlay>
          </div>
          <div class="week-controls">
            <div class="week-arrows">
              <IconSax
                v-if="isPreviousWeekAvailable()"
                type="bold"
                name="arrow-circle-left"
                @click="loadPreviousWeek()"
              />
              <div>Semana: {{ this.getWeekNumber }} de {{ getTotalWeeks }}</div>
              <IconSax
                v-if="isNextWeekAvailable()"
                type="bold"
                name="arrow-circle-right"
                @click="loadNextWeek()"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loading-spinner">
        <b-spinner
          style="width: 5rem; height: 5rem"
          label="Carregando..."
        ></b-spinner>
      </div>
      <div v-else class="schedule-columns">
        <Column
          v-for="(column, index) in week"
          :key="index"
          :title="column.title"
          :headerColor="column.headerColor"
          :titleColor="column.titleColor"
          :headerHeight="'5.25rem'"
          :isMobile="getIsMobile"
        >
          <ModuleCard :modules="column.modules" />
        </Column>
        <Column
          title="Correção de Redação"
          headerColor="#F7744A"
          titleColor="var(--neutral-white)"
          headerHeight="5.25rem"
          :isMobile="getIsMobile"
        >
          <ModuleCard :modules="weekRedacao" />
          <div class="module-card">
            <p class="module-description">
              Que tal praticar suas habilidades de
              <strong>Redação</strong> escolhendo um dos mais de 80 temas do
              Stoodi para praticar?
            </p>

            <ButtonPlay type="normal" class="full-width">
              <div class="icon-button">Iniciar Redação</div>
            </ButtonPlay>
          </div>
        </Column>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "StudySchedule",
  components: {
    Layout: () => import("@/layout/Layout.vue"),
    ButtonPlay: () => import("@/components/common/ButtonPlay.vue"),
    BreadCrumb: () => import("@/components/common/Breadcrumb.vue"),
    Column: () => import("@/components/StudySchedule/Column.vue"),
    ModuleCard: () => import("@/components/StudySchedule/ModuleCard.vue"),
  },
  computed: {
    ...mapGetters({
      getIsMobile: "layout/getIsMobile",
      getModules: "studySchedule/getModules",
      getTotalWeeks: "studySchedule/getTotalWeeks",
      getStartDate: "studySchedule/getStartDate",
      getCurrentWeekNumber: "studySchedule/getCurrentWeekNumber",
      getWeekNumber: "studySchedule/getWeekNumber",
      getIsLoading: "config/getIsLoading",
    }),
  },
  data() {
    return {
      isLoading: false,
      week: {
        linguagens: {
          title: "Linguagens, Códigos e suas Tecnologias",
          headerColor: "#F7744A",
          titleColor: "var(--neutral-white)",
          modules: [],
        },
        humanas: {
          title: "Ciências Humanas e suas Tecnologias",
          headerColor: "#F9C111",
          titleColor: "var(--neutral-gray-800)",
          modules: [],
        },
        natureza: {
          title: "Ciências da Natureza e suas Tecnologias",
          headerColor: "#1BB743",
          titleColor: "var(--neutral-white)",
          modules: [],
        },
        matematica: {
          title: "Matemática e Suas Tecnologias",
          headerColor: "#8726FF",
          titleColor: "var(--neutral-white)",
          modules: [],
        },
      },
      weekRedacao: [],
    };
  },
  methods: {
    ...mapActions({
      actionFetchSettings: "studySchedule/actionFetchSettings",
      actionFetchStudySchedule: "studySchedule/actionFetchStudySchedule",
      actionSetWeekNumber: "studySchedule/actionSetWeekNumber",
    }),
    goToConfiguration() {
      this.$router.push("/cronograma/criacao");
    },
    async loadWeek() {
      this.isLoading = true;

      await this.actionFetchStudySchedule({
        startWeek: this.getWeekNumber,
        endWeek: this.getWeekNumber,
      });

      this.mapWeekContent();
      this.isLoading = false;
    },
    async loadPreviousWeek() {
      if (this.isPreviousWeekAvailable()) {
        this.actionSetWeekNumber(this.getWeekNumber - 1);
        await this.loadWeek();
      }
    },
    async loadNextWeek() {
      if (this.isNextWeekAvailable()) {
        this.actionSetWeekNumber(this.getWeekNumber + 1);

        await this.loadWeek();
      }
    },
    isPreviousWeekAvailable() {
      return this.getWeekNumber > 1;
    },
    isNextWeekAvailable() {
      return (
        this.getWeekNumber < this.getTotalWeeks
        // && this.getWeekNumber <= this.getCurrentWeekNumber + 1
      );
    },
    extractModulesInCategroies(week, coursesInCategory) {
      let allModules = [];

      for (let course of coursesInCategory) {
        if (!week[course]) continue;
        allModules = allModules.concat(Object.values(week[course]));
      }

      return allModules;
    },
    mapWeekContent() {
      let currentWeek = this.getModules[`week${this.getWeekNumber}`];

      // Ciencias Humanas e suas Tecnologias
      this.week.humanas.modules =
        currentWeek["Ciências Humanas e suas Tecnologias"];

      // Ciências da Natureza e suas Tecnologias
      this.week.natureza.modules =
        currentWeek["Ciências da Natureza e suas Tecnologias"];

      // Matemática e Suas Tecnologias
      this.week.matematica.modules =
        currentWeek["Matemática e suas Tecnologias"];

      // Linguagens, Códigos e suas Tecnologias
      this.week.linguagens.modules =
        currentWeek["Linguagens, Códigos e suas Tecnologias"];

      // Redação
      this.weekRedacao = currentWeek["Correção de Redação"];
    },
    verifyScheduleConfigured() {
      if (!this.getStartDate) {
        this.$router.push("/cronograma/criacao");
      }
    },
    handleRedirectEssays () {
      this.$router.push("/essays");
    }
  },
  async created() {
    await this.actionFetchSettings();
    this.verifyScheduleConfigured();

    await this.loadWeek();
  },
};
</script>

<style lang="scss" scoped>
.container-study {
  padding: 1.5rem 2.25rem 8.31rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  h1 {
    font-family: Inter;
    font-weight: 600;
    font-size: 24px;
    color: var(--fontcolor);
    margin-top: 1.4rem;
  }

  p,
  label {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: var(--fontcolor);
    margin-top: 0.5rem;
  }
}

.week-controls {
  display: flex;
  gap: 1.5rem;
  align-items: center;

  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: var(--fontcolor);
  margin-top: 0.5rem;
}

.container-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
}

.week-configuration {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.schedule-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.schedule-columns {
  width: 100%;
  display: flex;
  gap: 1rem;
}

@media (max-width: 768px) {
  .schedule-columns {
    flex-direction: column;
  }

  .schedule-header {
    flex-direction: column;
    gap: 1rem;
  }

  .container-controls {
    width: 100%;
    align-items: center;

    .week-configuration {
      align-items: center;
      width: 100%;
    }

    .week-controls {
      width: 100%;
      justify-content: space-between;
    }

    .week-arrows {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .week-configuration {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

.schedule-columns > * {
  flex: 1;
}

.module-card {
  background-color: var(--neutral-white);
  border-radius: 4px;
  padding: 1rem;
  margin: 0.5rem 0;
  box-sizing: border-box;
  border: 1px solid var(--bordercolor2);

  &:hover {
    border-color: var(--maincolor);
  }

  &.completed-border {
    border-color: var(--success);
  }
}

.module-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  color: var(--maincolor);
}

.module-title-completed {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  color: var(--fontcolor);
}

.module-description {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: var(--fontcolor);
  margin-top: 0.5rem;
}

.module-footer,
.module-footer-finished {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  color: var(--maincolor);
}

.module-footer-finished {
  color: var(--success);
}

.full-width {
  width: 100%;
}

.icon-button,
.icon-button-success {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  ::v-deep .svgCss {
    svg {
      width: 1rem;
      height: 1rem;
    }

    svg path {
      fill: var(--maincolor) !important;
    }
  }
}

.week-arrows {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 1rem;

  ::v-deep .svgCss {
    svg {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }

    svg path {
      fill: var(--maincolor) !important;
    }
  }
}

.icon-button-success ::v-deep .svgCss svg path {
  fill: var(--success) !important;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20rem;

  @media screen and (max-width: 768px) {
    margin-top: 10rem;
  }
}
</style>
